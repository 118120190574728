import countries from '../../data/countries.json';

export function getCountryFromCode(value: string) {
  const country = countries.find(c => c.value === value);
  if (country) {
    return country.label;
  }
  return null;
}

export function getCodeFromCountry(label: string) {
  const country = countries.find(c => c.label === label);
  if (country) {
    return country.value;
  }
  return null;
}

