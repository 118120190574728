import React from 'react';
import { OrderItemPrice } from './swing-order-item-price';
import { SwingOrderItem as SwingOrderItemModel } from '../../../models/SwingOrder';
import './swing-order-item.scss';
import moment from 'moment';
import { dateFormat } from '../../../shared/utils/order-status-helper';
import { useTranslation } from 'react-i18next';

interface SwingOrderItemProps {
  item: SwingOrderItemModel;
  number: number;
  currency: string;
}

const SwingOrderItem = (props: SwingOrderItemProps) => {
  const {
    position,
    articleNumber,
    orderedAmout,
    deliveredAmount,
    productDescription,
    shippingDate,
    deliveryDate,
    netTotal,
    statusOverview,
  } = props.item;
  const overviewStatus = statusOverview.filter((so) => !!so.date).pop();
  const { t } = useTranslation();
  const frontendCalculatedUnitPrice = netTotal / orderedAmout;
  return (
    <>
      <tr className="swingorderitem">
        <td className="swingorderitem__number">{position}</td>
        <td className="swingorderitem__article">{articleNumber}</td>
        <td className="swingorderitem__description">{productDescription}</td>
        <td className="swingorderitem__amount">{`${orderedAmout} (${deliveredAmount})`}</td>
        <td className="swingorderitem__status">
          {overviewStatus && t(`order_details.order_item.overview_status.${overviewStatus.text.toLowerCase()}`)}
        </td>
        <td className="swingorderitem__shipping-date">{moment(shippingDate).format(dateFormat)}</td>
        <td className="swingorderitem__delivery-date">{moment(deliveryDate).format(dateFormat)}</td>
        <td className="swingorderitem__price">
          <OrderItemPrice value={frontendCalculatedUnitPrice} currency={props.currency} />
        </td>
        <td className="swingorderitem__price">
          <OrderItemPrice value={netTotal} currency={props.currency} />
        </td>
      </tr>
    </>
  );
};

export { SwingOrderItem };
