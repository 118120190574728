import React from 'react';
import { OrderItemImage } from './order-item-image';
import { OrderItemPrice } from './order-item-price';
import { OrderItemNameTree } from './order-item-name-tree';
import { OrderItem as OrderItemModel } from 'models/Order';
import './order-item.scss';

interface OrderItemProps {
  item: OrderItemModel;
  number: number;
}

const OrderItem = (props: OrderItemProps) => {
  const { image, name, orderAmount, currency, subItems, unitNetPrice, unitGrossPrice, tax, totalNetPrice } = props.item;
  const frontedCalculatedRowTotal = totalNetPrice * orderAmount;
  return (
    <>
      <tr className="orderitem">
        <td style={{ width: '5%' }} className="orderitem__number">{props.number}</td>
        <td style={{ width: '5%' }} className="orderitem__image">
          <OrderItemImage image={image} />
        </td>
        <td style={{ width: '35%' }} className="orderitem__description">
          <OrderItemNameTree name={name} isroot={true} subItems={subItems} />
        </td>
        <td style={{ width: '8%' }} className="orderitem__amount">{orderAmount}</td>
        <td style={{ width: '13%' }} className="orderitem__grossunitprice">
          <OrderItemPrice value={unitGrossPrice} currency={currency} />
        </td>
        <td style={{ width: '12%' }} className="orderitem__unitnetprice">
          <OrderItemPrice value={unitNetPrice} currency={currency} />
        </td>
        <td style={{ width: '10%' }} className="orderitem__tax">
          <OrderItemPrice value={tax} currency={currency} />
        </td>
        <td style={{ width: '12%' }} className="orderitem__totalnetprice">
          <OrderItemPrice value={frontedCalculatedRowTotal} currency={currency} />
        </td>
      </tr>
    </>
  );
};

export { OrderItem };
