import React from 'react';
import './swing-order-summary-address.scss';

interface OrderSummaryAddressProps {
  address: string[];
}

const SwingOrderSummaryAddress = (props: OrderSummaryAddressProps) => {
  const { address } = props;
  return (
    <>
      <div className="swingordersummaryaddress">
        {address.map((addressLine, i) => (
          <div key={i} className="swingordersummaryaddress__line">
            {addressLine}
          </div>
        ))}
      </div>
    </>
  );
};

export { SwingOrderSummaryAddress };

