import React, { useContext, HTMLProps } from 'react';
import { Container, interfaces } from 'inversify';

const InversifyContext = React.createContext<{ container: Container | null }>({ container: null });

type Props = {
  container: Container;
};

type IoCProviderProps = React.PropsWithChildren & HTMLProps<HTMLElement> & Props;

export const IoCProvider: React.FC<IoCProviderProps> = (props: IoCProviderProps) => {
  return (
    <InversifyContext.Provider value={{ container: props.container }}>
      {props.children}
    </InversifyContext.Provider>
  );
};

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
  const { container } = useContext(InversifyContext);
  return container.get<T>(identifier);
}
