import React from 'react';
import { SwingOrder } from '../../../models/SwingOrder';
import { SwingOrderItem } from '../swing-order-item/swing-order-item';
import { useTranslation } from 'react-i18next';
import './swing-order-summary.scss';
import { SwingOrderSummaryHeader } from './swing-order-summary-header';
import { SwingOrderSummaryTotals } from './swing-order-summary-totals';

interface SwingOrderSummaryProps {
  order: SwingOrder;
}

const SwingOrderSummary = ({ order }: SwingOrderSummaryProps) => {
  const { t } = useTranslation();
  return (
    <div className="swingordersummary">
      <SwingOrderSummaryHeader order={order} />
      <div className="swingordersummary__items__scroll">
        <table className="swingordersummary__items">
          <thead>
            <tr>
              <th className="swingordersummary__items__header">{'#'}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.article')}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.description')}</th>
              <th className="swingordersummary__items__header">{`${t('order_details.headers.quantity')} (${t(
                'order_details.headers.delivered',
              )})`}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.status')}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.shipping_date')}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.delivery_date')}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.unit_price')}</th>
              <th className="swingordersummary__items__header">{t('order_details.headers.net_value')}</th>
            </tr>
          </thead>
          <tbody>
            {order.orderItems
              .sort(function (a, b) {
                return a.position - b.position;
              })
              .map((item, idx) => (
                <SwingOrderItem key={item.id} number={idx + 1} item={item} currency={order.currency} />
              ))}
          </tbody>
        </table>
      </div>
      <SwingOrderSummaryTotals
        totalPositions={order.orderItems.length}
        netTotal={order.itemsNetTotal}
        currency={order.currency}
        grossTotal={order.itemsGrossTotal}
      />
    </div>
  );
};

export { SwingOrderSummary };
