import './heading-order-panel.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from 'core/ioc';
import { IConfigurationService, IConfigurationServiceType } from 'core/configuration';

import uploadIcon from './upload-icon.svg';
import illustrationOrderManagement from './illustration-ordermgnt.svg';

export function OrdersHeadingPanel(): React.JSX.Element {
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const config = configurationService.get();

  function openShoppingCart() {
    window.open(config.shoppingCartUrl, '_blank');
  }

  const { t } = useTranslation();

  return (
    <section className="hw-heading-order-panel">
      <div className="hw-heading-order-panel__container hw-centered-content">
        <div className="hw-heading-order-panel__main">
          <h1>{t('heading_order_panel.title')}</h1>
          <p>{t('heading_order_panel.subtitle')}</p>
          <button className="hw-heading-order-panel__upload" onClick={openShoppingCart}>
            <img alt={t('heading_order_panel.title')} src={uploadIcon} />
            &nbsp;&nbsp;
            <span>{t('heading_order_panel.button')}</span>
          </button>
        </div>
        <div className="hw-heading-order-panel__image">
          <img alt="" src={illustrationOrderManagement} />
        </div>
      </div>
    </section>
  );
}
