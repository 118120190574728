import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../shared/loader/loader';
import { useGenericListLoader } from 'core/http/generic-http-hooks';
import { useInjection } from 'core/ioc';
import { IConfigurationService, IConfigurationServiceType } from 'core/configuration';
import { OwnOrderRow } from './own-order-row';
import { Order } from 'models/Order';
import { OrderDetailsModal } from '../order-details/order-details-modal';
import { PageSizeSelector, Pagination, SearchBar } from '../../shared/layout';
import { InvoiceViewer } from '../../orders/invoice-viewer';

const defaultPageSize = 24;

const OwnOrderList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const config = configurationService.get();
  const {
    items: orders,
    isLoading,
    totalPages,
  } = useGenericListLoader<Order>(`${config.ordersApiEndpoint}/api/v1/orders/mine`, page, pageSize, filter);

  return (
    <>
      <div className="d-flex mb-2">
        <div className="filter-wrapper">
          <div>
            <SearchBar
              placeholder={t('common.actions.search.orders')}
              handleSearch={(query: string) => setFilter(query)}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="pagination-controls">
            {totalPages > 1 && <PageSizeSelector onChangeSize={setPageSize} />}
            {totalPages > 1 && <Pagination current={page} totalPages={totalPages} onSelection={setPage} />}
          </div>
          <div className="hw-orders-list__table table-responsive">
            <table className="table table-striped hw-table">
              <thead>
                <tr>
                  <th scope="col">{t('orders_list.table.order_number')}</th>
                  <th scope="col"></th>
                  <th scope="col">{t('orders_list.table.status')}</th>
                  <th scope="col"></th>
                  <th scope="col">{t('orders_list.table.order_date')}</th>
                  <th scope="col">{t('orders_list.table.net_value')}</th>
                  <th scope="col">{}</th>
                </tr>
              </thead>
              <tbody>
                {orders.length === 0 ? (
                  <tr>
                    <td colSpan={8}>{t('orders_list.empty')}</td>
                  </tr>
                ) : (
                  orders.map((order) => <OwnOrderRow key={order.id} order={order} />)
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination-controls">
            {totalPages > 1 && <PageSizeSelector onChangeSize={setPageSize} />}
            {totalPages > 1 && <Pagination current={page} totalPages={totalPages} onSelection={setPage} />}
          </div>
        </>
      )}
      <Routes>
        <Route path={'details/:orderId'} element={<OrderDetailsModal />} />
        <Route path={'invoice/:invoiceId'} element={<InvoiceViewer />} />
      </Routes>
    </>
  );
};

export { OwnOrderList };
