import React, { useState } from 'react';
import './order-item-name-tree.scss';
import { OrderItem } from '../../../models/Order';
import { useTranslation } from 'react-i18next';

import chevronRight from './lnr-chevron-right.svg';

interface OrderItemNameTreeProps {
  name: string;
  isroot: boolean;
  subItems: OrderItem[] | null;
}

const OrderItemNameTree = (props: OrderItemNameTreeProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const iconClassName = expanded
    ? 'orderitemnametree__chevron__icon orderitemnametree__chevron__icon__expanded'
    : 'orderitemnametree__chevron__icon';
  let titleClassName =
    props.subItems !== null && props.subItems.length !== 0
      ? 'orderitemnametree__title__withchildren'
      : 'orderitemnametree__title__withoutchildren';

  titleClassName += props.isroot ? '' : ' orderitemnametree__subtitle';
  const toggleTree = () => {
    setExpanded(!expanded);
  };

  const childrenClassName = expanded
    ? 'orderitemnametree__padder orderitemnametree__shown'
    : 'orderitemnametree__padder orderitemnametree__hidden';
  return (
    <div className="productnametree">
      <div className={titleClassName} onClick={toggleTree}>
        {props.subItems !== null && props.subItems.length !== 0 ? (
          <div className={iconClassName}>
            <img
              alt="caret"
              className={props.isroot ? 'orderitemnametree__rooticon' : 'orderitemnametree__subicon'}
              src={chevronRight}
              title={t('product.has_subproducts')}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={props.isroot ? 'orderitemnametree__rootname' : 'orderitemnametree__subname'}>{props.name}</div>
      </div>
      <div className={childrenClassName}>
        {props.subItems !== null ? (
          props.subItems.map((subitem) => (
            <OrderItemNameTree key={subitem.id} name={subitem.name} isroot={false} subItems={subitem.subItems} />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export { OrderItemNameTree };
