import './error-pages.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import e404 from './error_404.svg';

export function NotFound(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="hw-error-page">
      <div className="hw-error-page__container hw-centered-content">
        <img alt="Not found" className="hw-error-page__logo" src={e404} />
        <div className="hw-error-page__title">{t('common.errors.not_found.title')}</div>
        <div className="hw-error-page__description">{t('common.errors.not_found.description')}</div>
        <Link to={'/'} className="hw-error-page__button btn btn-outline-primary">
          {t('common.actions.go_back')}
        </Link>
      </div>
    </div>
  );
}