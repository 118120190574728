import React, { useEffect, useState } from 'react';
import { useDebounce } from '../hooks';

interface SearchBarProps {
  placeholder: string;
  handleSearch(query: string): void;
}

const debounceInterval = 500;

export function SearchBar({
  placeholder,
  handleSearch,
}: SearchBarProps): React.JSX.Element {
  const [searchTerm, setSearchTerm] = useState('');
  const { debouncedValue } = useDebounce(searchTerm, debounceInterval);

  useEffect(() => {
    handleSearch(debouncedValue);
  }, [debouncedValue, handleSearch]);

  return (
    <div className="form">
      <div className="input-group">
        <div className="input-group-text">
          <i title="Search" className="bi bi-search"></i>
        </div>
        <input
          className="form-control pr-4"
          type="text"
          placeholder={placeholder}
          onChange={(e) => setSearchTerm(e.target.value)}
        ></input>
      </div>
    </div>
  );
}
