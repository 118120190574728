import './loader.scss';
import React from 'react';

export function Loader(): React.JSX.Element {

  return (
    <div className="hw-loader">
      <div className="spinner-border">
        <span className="visually-hidden-focusable">{'Loading...'}</span>
      </div>
    </div>
  );
}