import React from 'react';
import { Order } from 'models/Order';
import { OrderItem } from '../order-item/order-item';
import { useTranslation } from 'react-i18next';
import './order-summary.scss';
import { OrderSummaryHeader } from './order-summary-header';
import { OrderSummaryTotals } from './order-summary-totals';

interface OrderSummaryProps {
  order: Order;
}

const OrderSummary = ({ order }: OrderSummaryProps) => {
  const { t } = useTranslation();
  return (
    <div className="ordersummary">
      <OrderSummaryHeader order={order} />
      <div className="ordersummary__items__scroll">
        <table className="ordersummary__items">
          <thead>
            <tr>
              <th className="ordersummary__items__header">{'#'}</th>
              <th>{}</th>
              <th>{t('order_details.headers.description')}</th>
              <th className="ordersummary__items__header">{t('order_details.headers.quantity')}</th>
              <th className="ordersummary__items__header">{t('order_details.headers.unit_gross_price')}</th>
              <th className="ordersummary__items__header">{t('order_details.headers.unit_net_price')}</th>
              <th className="ordersummary__items__header">{t('order_details.headers.tax')}</th>
              <th className="ordersummary__items__header">{t('order_details.headers.rowtotals')}</th>
            </tr>
          </thead>
          <tbody>
            {order.items
              .sort(function (a, b) {
                return a.orderPosition - b.orderPosition;
              })
              .map((item, idx) => (
                <OrderItem key={item.id} number={idx + 1} item={item} />
              ))}
          </tbody>
        </table>
      </div>
      <OrderSummaryTotals totals={order.totals} />
    </div>
  );
};

export { OrderSummary };
