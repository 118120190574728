import { useEffect, useState } from 'react';
import { IHttpFactory, IHttpFactoryType } from '../../core/http';
import { IConfigurationService, IConfigurationServiceType } from '../../core/configuration';
import { useInjection } from '../../core/ioc';
import { Invoice } from 'models/Invoice';

interface UseSwingInvoiceResult {
  invoice: Invoice;
  isLoading: boolean;
}
export function useSwingInvoice(invoiceNumber: string): UseSwingInvoiceResult {
  const [invoice, setInvoice] = useState<Invoice>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  useEffect(() => {
    setIsLoading(true);
    loadInvoice(httpFactory, configurationService, invoiceNumber).then((invoice) => {
      setInvoice(invoice);
      setIsLoading(false);
    });
  }, [configurationService, httpFactory, invoiceNumber]);

  return { invoice, isLoading };
}

export const loadInvoice = async (
  httpFactory: IHttpFactory,
  configurationService: IConfigurationService,
  invoiceNumber: string,
): Promise<Invoice> => {
  const http = httpFactory.build();
  const apiUrl = configurationService.get().ordersApiEndpoint;
  try {
    let invoice = await http.get<Invoice>(`${apiUrl}/api/v1/invoices/${invoiceNumber}/pdf`);
    return invoice;
  } catch (e) {
    console.log(e);
  }
  return { invoiceNumber: '', content: '' };
};
