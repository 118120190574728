import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderTotals } from 'models/Order';
import { OrderItemPrice } from '../order-item';

interface OrderSummaryTotalsProps {
  totals: OrderTotals;
}

const OrderSummaryTotals = ({ totals }: OrderSummaryTotalsProps) => {
  const { t } = useTranslation();
  const { totalPositions, netTotal, currency } = totals;
  return (
    <table className="ordersummarysection ordersummarysection__footer">
      <tbody className="ordersummarytotals">
        <tr>
          <td style={{ width: '50%' }}>
            <span className="ordersummarysection__label">
              {t('order_details.labels.totalPositions')}
              {': '}
            </span>
            {totalPositions}
          </td>
          <td style={{ width: '50%', textAlign: 'right' }}>
            <span className="ordersummarysection__label">
              {t('order_details.labels.grandTotal')}
              {': '}
            </span>
            {<OrderItemPrice value={netTotal} currency={currency} />}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { OrderSummaryTotals };
