import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Order, OrderStatus, OrderStatusName } from '../../models/Order';
import { moneyFormatter } from '../../shared/utils/money-formatter';
import { orderStatusStyle } from '../../shared/utils/order-status-helper';
import { InvoiceLinks } from '../../orders/invoice-viewer';

const dateFormat = 'DD-MM-YYYY';

const OwnOrderRow = ({ order }: { order: Order }): React.JSX.Element => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const openOrderDetail = useCallback(
    (orderId: string) => {
      navigate(`details/${orderId}${window.location.search}`);
    },
    [navigate],
  );

  const severity = orderStatusStyle[order.status];
  return (
    <>
      <tr className="tr-clickable">
        <td className={`table-row--${severity}`}>{order.orderNumber}</td>
        <td>
          <button className="btn btn-primary" onClick={() => openOrderDetail(order.id)}>
            <i title={t('orders_list.table.order_details_title')} className="bi bi-card-list"></i>
          </button>
        </td>
        <td className={`text-${severity}`}>{t(OrderStatusName[order.status])}</td>
        <td>
          {order.status === OrderStatus.LinkedToSwing && (
            <i className="bi bi-check" title={t('common.orders.statusnames.linkedtoswing')}></i>
          )}
          {order.status >= OrderStatus.Acknowledged && (
            <i className="bi bi-check-all" title={t('common.orders.statusnames.acknowledged')}></i>
          )}
          &nbsp;
          {order.status >= OrderStatus.Invoiced && (
            <i className="bi bi-receipt" title={t('common.orders.statusnames.invoiced')}></i>
          )}
        </td>
        <td>{moment(order.orderDate).format(dateFormat)}</td>
        <td>
          {moneyFormatter({
            amount: order.totals.netTotal,
            currency: order.totals.currency,
            language: i18n.language,
          })}
        </td>
        <td>{order.hasInvoicePdf && <InvoiceLinks invoiceNumber={order.invoiceNumber} />}</td>
      </tr>
      <tr className="spacer"></tr>
      <tr className="spacer"></tr>
    </>
  );
};

export { OwnOrderRow };
