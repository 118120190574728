import { useState, useEffect } from 'react';

export interface useDebounceResult {
  debouncedValue: string;
}

export function useDebounce(value: string, delay: number): useDebounceResult {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Clear Timeout if value | delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return { debouncedValue };
}