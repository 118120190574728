import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const acceptedLanguages = ['de', 'en', 'fr', 'pt', 'zh-CN', 'ja'];
interface PoeditorResponse {
  response: { status: string; code: string; message: string };
  result: {
    terms: [
      {
        term: string;
        context: string;
        translation: {
          content: string;
        };
      },
    ];
  };
}
export async function configureI18N(suiteOrigin: string) {
  const localhost = window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1');
  const initConfiguration: InitOptions = {
    fallbackLng: acceptedLanguages,
    supportedLngs: acceptedLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      lookupCookie: 'ServiceHubLng',
      caches: ['cookie', 'localStorage'],
      cookieMinutes: Number.MAX_VALUE,
      cookieDomain: suiteOrigin,
    },
  };

  if (!localhost) {
    initConfiguration.backend = {
      loadPath: '/api/locales?lng={{lng}}&ns={{ns}}',
      allowMultiLoading: true,
      parse: (data: string) => {
        const response: PoeditorResponse = JSON.parse(data);
        const languageData = {};
        response.result.terms.forEach((termEl) => {
          const termKeys = termEl.context.split('.').map((key) => JSON.parse(key));
          termKeys.push(termEl.term);
          const lastKey = termKeys.length - 1;
          termKeys.reduce((val, el, idx) => {
            if (idx === lastKey) {
              val[el] = termEl.translation.content;
              return {};
            }
            if (!val[el]) {
              val[el] = {};
            }
            return val[el];
          }, languageData);
        });
        return languageData;
      },
    };
    initConfiguration.ns = 'haworth-management';
    initConfiguration.defaultNS = 'haworth-management';
  }

  i18n.use(Backend).use(LanguageDetector).use(initReactI18next);
  await i18n.init(initConfiguration);
}

export default i18n;
