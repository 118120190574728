import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface PaginationProps {
  label?: string;
  current: number;
  totalPages: number;
  onSelection: (selection: number) => void;
}

function generatePages(current: number, totalPages: number): number[] {
  return [current - 2, current - 1, current, current + 1, current + 2]
    .filter(p => p > 0 && p < totalPages);
}

export function Pagination({ label, current, totalPages, onSelection }: PaginationProps): React.JSX.Element {
  const { t } = useTranslation();
  const pages = generatePages(current, totalPages);
  const prevPage = pages.find(p => p === current - 1);
  const nextPage = pages.find(p => p === current + 1);
  return (
    <nav aria-label={label}>
      <ul className="pagination">
        <li className={classNames('page-item', {
          'disabled': !prevPage
        })}>
          <button className="page-link" onClick={() => onSelection(prevPage)} disabled={!prevPage}>{t('common.actions.previous')}</button>
        </li>
        {
          pages.map(p => (
            <li key={p} className={classNames('page-item', {
              'active': current === p
            })}>
              <button className="page-link" onClick={() => onSelection(p)}>{p}</button>
            </li>
          ))
        }
        {
          <li className={classNames('page-item', {
            'disabled': !nextPage
          })}>
            <button className="page-link" onClick={() => onSelection(nextPage)} disabled={!nextPage}>{t('common.actions.next')}</button>
          </li>
        }
      </ul>
    </nav>
  );
}
