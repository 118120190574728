import { User } from 'oidc-client';

export interface IAuthenticationService {
  login(): void;
  getUser(): Promise<User>;
  getToken(): Promise<string>;
  userHasLicence(): Promise<boolean>;
}

export const IAuthenticationServiceType = Symbol.for('IAuthenticationService');
