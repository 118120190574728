import React from 'react';
import { IHttpFactory, IHttp } from '.';
import axios, { CancelTokenSource, CancelToken } from 'axios';
import { injectable, inject } from 'inversify';
import type { IAuthenticationService } from 'core/authentication';
import { IAuthenticationServiceType } from 'core/authentication';
import { toast } from 'react-toastify';
import HttpStatus from 'http-status-codes';
import i18n from 'i18n';
import { createBrowserHistory } from 'history';

@injectable()
export class HttpFactory implements IHttpFactory {
  @inject(IAuthenticationServiceType)
  private authenticationService: IAuthenticationService;

  public build(cancelToken?: CancelToken): IHttp {
    const http = axios.create();
    const history = createBrowserHistory();

    http.interceptors.request.use(async (config) => {
      const accessToken = await this.authenticationService.getToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      if (cancelToken) {
        config.cancelToken = cancelToken;
      }

      return config;
    });

    http.interceptors.response.use(
      (response) => response.data,
      (error) => {
        const httpStatus = error?.response?.status;
        if (httpStatus === HttpStatus.INTERNAL_SERVER_ERROR) {
          toast.error(i18n.t('common.errors.internal_server_error'));
        } else if (httpStatus === HttpStatus.BAD_REQUEST) {
          toast.error(i18n.t(`common.errors.bad_request.${error.response.data}`));
        } else if (httpStatus === HttpStatus.FORBIDDEN) {
          history.push('/forbidden');
        }

        return Promise.reject(error);
      },
    );

    return http;
  }
  public getCancelTokenSource(): CancelTokenSource {
    return axios.CancelToken.source();
  }
}

export function renewCancelToken(ref: React.MutableRefObject<CancelTokenSource>): CancelToken {
  if (ref.current) {
    ref.current.cancel();
  }
  ref.current = axios.CancelToken.source();

  return ref.current.token;
}
