interface MoneyFormatterProps {
  amount: number;
  currency: string;
  language: string;
}
export function moneyFormatter({
  amount,
  currency,
  language,
}: MoneyFormatterProps): string {
  try {
    return new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
    }).format(amount);
  } catch (e) {
    return `${new Intl.NumberFormat(language).format(amount)} ${currency}`;
  }
}
