import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from 'models/Order';
import { OrderSummaryAddress } from './order-summary-address';

interface OrderSummaryHeaderProps {
  order: Order;
}

const OrderSummaryHeader = (props: OrderSummaryHeaderProps) => {
  const { orderNumber, orderDate, customerNumber, deliveryDate } = props.order;
  const dateFormat = 'DD-MM-YYYY';
  const { billing, shipping } = props.order.addresses;
  const { t } = useTranslation();
  return (
    <table className="ordersummarysection ordersummarysection__header">
      <tbody>
        <tr>
          <td style={{ width: '10%' }}>
            <span className="ordersummarysection__label">{t('order_details.labels.orderNumber')}</span> {orderNumber}
          </td>
          <td style={{ width: '20%' }}>
            <span className="ordersummarysection__label">{t('order_details.labels.customerOrderNumber')}</span>{' '}
            {customerNumber}
          </td>
          <td style={{ width: '25%' }}>
            <span className="ordersummarysection__label">{t('order_details.labels.orderDate')}:</span>{' '}
            {moment(orderDate).format(dateFormat)}
          </td>
          <td style={{ width: '25%' }}>
            <span className="ordersummarysection__label">{t('order_details.labels.deliveryDate')}:</span>{' '}
            {moment(deliveryDate).format(dateFormat)}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className="ordersummarysection__label">{t('order_details.labels.billingAddress')}</div>
            <OrderSummaryAddress address={billing} />
          </td>
          <td colSpan={2}>
            <div className="ordersummarysection__label">{t('order_details.labels.shippingAddress')}</div>
            <OrderSummaryAddress address={shipping} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { OrderSummaryHeader };
