import './header.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import haworthLogo from './haworth-logo.svg';

export function Header(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <header>
        <Link className="hw-header" to="/" aria-label={t('home.title')}>
          <img alt="Haworth logo" className="hw-header-logo" src={haworthLogo} />
          <span className="hw-header-title">Orders Management</span>
        </Link>
      </header>
    </>
  );
}