import { useInjection } from 'core/ioc';
import i18n from 'i18next';
import React from 'react';
import { IConfigurationService, IConfigurationServiceType } from '../../../core/configuration';

export function ServiceFooter(): React.JSX.Element {
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const config = configurationService.get();

  return (
    <service-footer selected-language={i18n.language} settings-uri={config.serviceBar.serviceBarSettingsUri}></service-footer>
  );
}

