import { Loader } from 'shared/loader/loader';
import { Modal } from 'shared/modal';
import { getRouteListFromDetail } from 'shared/utils/order-status-helper';
import { OrderNotFound } from 'orders/swing-order-details/order-not-found';
import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSwingInvoice } from './useSwingInvoice';

export const InvoiceViewer = () => {
  const [isOpen, setIsOpen] = useState(true);
  const params = useParams<{ invoiceId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { invoice, isLoading } = useSwingInvoice(params.invoiceId);
  const closeHandler = () => {
    setIsOpen(false);
    navigate(getRouteListFromDetail(location, '/invoice'));
    setIsOpen(true);
  };
  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      {isLoading ? (
        <Loader />
      ) : invoice != null ? (
        <object
          aria-label="Embedded PDF document viewer"
          style={{
            position: 'relative',
            zIndex: 9,
            width: '80vw',
            height: '80vh',
          }}
          type="application/pdf"
          data={`data:application/pdf;base64,${invoice.content}`}
        ></object>
      ) : (
        <OrderNotFound />
      )}
    </Modal>
  );
};
