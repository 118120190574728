import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './PageSizeSelector.scss';

const pageSizes = [12, 24, 48, 96] as const;
type pageSizesType = typeof pageSizes[number];

interface PageSizeSelectorProps {
  defaultSize?: pageSizesType;
  onChangeSize?: (size: number) => void;
}

export const PageSizeSelector: React.FC<PageSizeSelectorProps> = ({
  defaultSize = pageSizes[1],
  onChangeSize = () => { }
}) => {
  const { t } = useTranslation();
  const [currentSize, setCurrentSize] = useState<number>(null);

  const onChangePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
    const val = parseInt(event.target.value);
    setCurrentSize(val);
    onChangeSize(val);
  };

  return (
    <div className="form-group page-size-selector">
      <select
        onChange={onChangePageSize}
        value={currentSize || defaultSize}
        className="form-control">
        {pageSizes.map((size, index) => (
          <option key={index} value={size}>{`${size} ${t('common.form.perPage')}`}</option>
        ))}
      </select>
    </div>
  );
};