import i18n from 'i18next';
import React from 'react';
import { useEffect } from 'react';
import { useInjection } from 'core/ioc';
import { IConfigurationServiceType, IConfigurationService } from 'core/configuration';
export function ServiceBar(): React.JSX.Element {
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const config = configurationService.get();
  useEffect(() => {
    customElements.whenDefined('service-bar').then(() => {
      /* global HTMLServiceBarElement */
      const servicebarEl = document.querySelector('service-bar') as HTMLServiceBarElement;
      servicebarEl.addEventListener('languageSet', (event: any) => {
        i18n.changeLanguage(event.detail);
      });
    });
  }, []);
  return (
    <service-bar
      selected-language={i18n.language}
      languages-json={JSON.stringify(i18n.languages)}
      style={{ fontSize: '16px' }}
      client-id={config.serviceBar.clientId}
      scope={config.serviceBar.clientScope}
      settings-uri={config.serviceBar.serviceBarSettingsUri}/>
  );
}