import React from 'react';
import { moneyFormatter } from 'shared/utils/money-formatter';
import { useTranslation } from 'react-i18next';

interface OrderItemProps {
  value: number;
  currency: string;
}

const OrderItemPrice = (props: OrderItemProps) => {
  const { i18n } = useTranslation();
  const formatValue = ({ value, currency }: OrderItemProps) => {
    return moneyFormatter({ amount: value, currency, language: i18n.language });
  };

  return (
    <>
      <span className="orderitemprice">{formatValue(props).replace(/^(\D+)/, '$1 ')}</span>
    </>
  );
};

export { OrderItemPrice };
