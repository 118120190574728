import { OrderStatus } from '../../models/Order';
import { Location as LocationRouter } from 'history';

export const orderStatusStyle: { [key: string]: string } = {
  [OrderStatus.New]: 'warning',
  [OrderStatus.Cancelled]: 'danger',
  [OrderStatus.SentForProcessing]: 'warning',
  [OrderStatus.LinkedToSwing]: 'success',
  [OrderStatus.Acknowledged]: 'success',
  [OrderStatus.InvoiceSent]: 'success',
  [OrderStatus.Invoiced]: 'success',
};

export const swingOrderStatusStyle: { [key:string]: string} = {
  'complete': 'success',
  'incomplete': 'warning'
};

export const getRouteListFromDetail = (location: LocationRouter, route:string = '/details') => {
  const { pathname } = location;
  const newRoute = pathname.substr(0, pathname.indexOf(route));
  return newRoute;
};



export const dateFormat = 'DD-MM-YYYY';