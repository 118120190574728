import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderItemPrice } from '../swing-order-item';

interface OrderSummaryTotalsProps {
  netTotal: number;
  grossTotal: number;
  totalPositions: number;
  currency: string;
}

const SwingOrderSummaryTotals = ({ netTotal, grossTotal, totalPositions, currency }: OrderSummaryTotalsProps) => {
  const { t } = useTranslation();
  const frontendCalculatedDiscount = grossTotal - netTotal;
  return (
    <table className="ordersummarysection ordersummarysection__footer">
      <tbody className="ordersummarytotals">
        <tr>
          <td style={{ width: '50%' }}>
            <span className="ordersummarysection__label">
              {t('order_details.labels.totalPositions')}
              {': '}
            </span>
            {totalPositions}
          </td>
          <td style={{ width: '50%', textAlign: 'right' }}>
            {
              frontendCalculatedDiscount !== 0 &&
              (
                <div>
                  <span className="ordersummarysection__label">
                    {t('order_details.labels.grossTotal')}
                    {': '}
                  </span>
                  {<OrderItemPrice value={grossTotal} currency={currency} />}
                </div>
              )
            }
            {
              frontendCalculatedDiscount !== 0 &&
              (
                <div>
                  <span className="ordersummarysection__label">
                    {t('order_details.labels.discount')}
                    {': '}
                  </span>
                  {<OrderItemPrice value={frontendCalculatedDiscount} currency={currency} />}
                </div>
              )
            }
            <div>
              <span className="ordersummarysection__label">
                {t('order_details.labels.grandTotal')}
                {': '}
              </span>
              {<OrderItemPrice value={netTotal} currency={currency} />}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { SwingOrderSummaryTotals };
