import { Loader } from '../../shared/loader/loader';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../shared/modal';
import { SwingOrderSummary } from './swing-order-summary/swing-order-summary';
import { useSwingOrder } from './useSwingOrderHook';
import { getRouteListFromDetail } from '../../shared/utils/order-status-helper';
import { OrderNotFound } from './order-not-found';

const SwingOrderDetailsModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const params = useParams<{ orderId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { order, isLoading } = useSwingOrder(params.orderId);
  const closeHandler = () => {
    setIsOpen(false);
    navigate(getRouteListFromDetail(location));
    setIsOpen(true);
  };
  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      {isLoading ? <Loader /> : order != null ? <SwingOrderSummary order={order} /> : <OrderNotFound />}
    </Modal>
  );
};

export { SwingOrderDetailsModal };
