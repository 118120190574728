import { injectable } from 'inversify';
import { Configuration } from './configuration.models';
import { IConfigurationService } from './iconfiguration.service';

@injectable()
export class ConfigurationService implements IConfigurationService {
  public configuration: Configuration;

  public async load(): Promise<void> {
    const response = await fetch('/api/configuration');
    this.configuration = await response.json();
  }

  get(): Configuration {
    if (!this.configuration) {
      throw new Error('Configuration is not loaded yet');
    }
    return this.configuration;
  }
}
