import { injectable } from 'inversify';
import { IAuthenticationService } from './iauthentication.service';
import 'haworth-service-bar';
import { User } from '.';

/* global HTMLServiceBarElement */

@injectable()
export class AuthenticationService implements IAuthenticationService {
  private user: User = null;
  private get bar() {
    return document.querySelector('service-bar') as HTMLServiceBarElement;
  }

  public login(): void {
    const event = new Event('requestSBUserLogin');
    this.bar.dispatchEvent(event);
  }

  public async getUser(): Promise<User> {
    if (this.user == null) {
      this.user = await this.bar.getUser();
    }
    return this.user;
  }

  public async getToken(): Promise<string> {
    const user = await this.getUser();
    if (user) {
      return user.access_token;
    } else {
      return null;
    }
  }
  public async userHasLicence(): Promise<boolean> {
    return this.bar.userHasLicence('OrderManagement');
  }
}
