import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OwnOrderList } from './own-order-list/own-order-list';
import { SwingOrderList } from './swing-order-list/swing-order-list';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import { OrdersHeadingPanel } from './heading';
import { IAuthenticationService, IAuthenticationServiceType } from 'core/authentication';
import { useInjection } from 'core/ioc';
import { IHttpFactory, IHttpFactoryType } from 'core/http';
import qs from 'qs';

const Orders = () => {
  const { t } = useTranslation();
  const [showERPOrders, setShowERPOrders] = useState(false);
  const authenticationService = useInjection<IAuthenticationService>(IAuthenticationServiceType);
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  let right = useMemo(async () => {
    const user = await authenticationService.getUser();
    const groupIds = user.profile.groups;
    const client = httpFactory.build();
    return await client.get('/api/usergroup/right-to-display-erp-orders', {
      params: { groupIds },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }, [authenticationService, httpFactory]);
  useEffect(() => {
    const getUserInfo = async () => {
      setShowERPOrders((await right) as boolean);
    };
    getUserInfo();
  }, [right]);
  return (
    <>
      <OrdersHeadingPanel />
      <div className="hw-centered-content">
        <ul className="nav nav-tabs mt-5 mb-3">
          {showERPOrders && (
            <li className="nav-item">
              <NavLink className="nav-link" to={'/orders/swing'}>
                <span>{t('nav.swing')}</span>
              </NavLink>
            </li>
          )}
          <li className="nav-item">
            <NavLink className="nav-link" to={'/orders/mine'}>
              <span>{t('nav.mine')}</span>
            </NavLink>
          </li>
        </ul>
        <Routes>
          <Route
            path={'*'}
            element={showERPOrders ? <Navigate to={'swing'} /> : <Navigate to={'mine'} />}
          />
          <Route path={'swing/*'} element={<SwingOrderList />} />
          <Route path={'mine/*'} element={<OwnOrderList />} />
        </Routes>
      </div>
    </>
  );
};

export { Orders };
