import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../shared/loader/loader';
import { useGenericListLoader } from '../../core/http/generic-http-hooks';
import { useInjection } from '../../core/ioc';
import { SearchBar, PageSizeSelector, Pagination } from '../../shared/layout';
import { IConfigurationService, IConfigurationServiceType } from '../../core/configuration';
import { SwingOrderRow } from './swing-order-row';
import { SwingOrder } from '../../models/SwingOrder';
import { SwingOrderDetailsModal } from '../swing-order-details/swing-order-details-modal';
import { InvoiceViewer } from '../invoice-viewer';

const defaultPageSize = 24;

const SwingOrderList = () => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const { t } = useTranslation();
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const config = configurationService.get();
  const {
    items: orders,
    isLoading,
    totalPages,
  } = useGenericListLoader<SwingOrder>(`${config.ordersApiEndpoint}/api/v1/swing-orders/mine`, page, pageSize, filter);

  return (
    <>
      <div className="d-flex mb-2">
        <div className="filter-wrapper">
          <div>
            <SearchBar
              placeholder={t('common.actions.search.orders')}
              handleSearch={(query: string) => setFilter(query)}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="pagination-controls">
            {totalPages > 1 && <PageSizeSelector onChangeSize={setPageSize} />}
            {totalPages > 1 && <Pagination current={page} totalPages={totalPages} onSelection={setPage} />}
          </div>
          <div className="hw-orders-list__table table-responsive">
            <table className="table table-striped hw-table">
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>
                    {t('orders_list.table.erp_order_number')}
                  </th>
                  <th scope="col">{t('orders_list.table.order_number')}</th>
                  <th scope="col">{t('orders_list.table.status')}</th>
                  <th scope="col">{t('orders_list.table.order_date')}</th>
                  <th scope="col">{t('orders_list.table.eta_date')}</th>
                  <th scope="col">{t('orders_list.table.net_value')}</th>
                  <th scope="col">{`${t('orders_list.table.order_customerName')} (${t(
                    'orders_list.table.order_customerNumber',
                  )})`}</th>
                  <th scope="col">{t('orders_list.table.invoice')}</th>
                </tr>
              </thead>
              <tbody>
                {orders.length === 0 ? (
                  <tr>
                    <td colSpan={9}>{t('orders_list.empty')}</td>
                  </tr>
                ) : (
                  orders
                    .filter((o) => o.overallStatus !== 'sync-error')
                    .map((order, i) => <SwingOrderRow key={`${order.id}${i}`} order={order} />)
                )}
              </tbody>
            </table>
            <div className="pagination-controls">
              {totalPages > 1 && <PageSizeSelector onChangeSize={setPageSize} />}
              {totalPages > 1 && <Pagination current={page} totalPages={totalPages} onSelection={setPage} />}
            </div>
          </div>
        </>
      )}
      <Routes>
        <Route path={'details/:orderId'} element={<SwingOrderDetailsModal />} />
        <Route path={'invoice/:invoiceId'} element={<InvoiceViewer />} />
      </Routes>
    </>
  );
};

export { SwingOrderList };
