import { useCallback, useEffect, useState } from 'react';
import { IHttpFactory, IHttpFactoryType } from '../../core/http';
import { IConfigurationService, IConfigurationServiceType } from '../../core/configuration';
import { useInjection } from '../../core/ioc';
import { Order } from '../../models/Order';
interface UseOrderResult {
  order: Order;
  isLoading: boolean;
  reload: () => Promise<void>;
}

export function useOrder(orderId: string): UseOrderResult {
  const [order, setOrder] = useState<Order>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);

  const loadOrder = useCallback(() => {
    const http = httpFactory.build();
    const apiUrl = configurationService.get().ordersApiEndpoint;
    return http
      .get<Order>(`${apiUrl}/api/v1/orders/${orderId}`)
      .then((resultOrder) => {
        setOrder(resultOrder);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [configurationService, httpFactory, orderId]);

  useEffect(() => {
    setIsLoading(true);
    loadOrder().then(() => setIsLoading(false));
  }, [loadOrder]);

  return { order, isLoading, reload: loadOrder };
}
