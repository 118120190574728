import { Configuration } from 'core/configuration';
import { googleTagManagerService } from './googleTagManager.service';

class AnalyticsService {
  public async init(configuration: Configuration) {
    await googleTagManagerService.init(configuration.googleTagManagerTrackingId);
  }

  public async pageView(pagePath: string, pageTitle: string) {
    googleTagManagerService.pageView(pagePath, pageTitle);
  }
}

export const analyticsService = new AnalyticsService();
