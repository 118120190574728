import { Loader } from '../../shared/loader/loader';
import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Modal } from '../../shared/modal';
import { OrderSummary } from './order-summary/order-summary';
import { useOrder } from './useOrderHook';
import { getRouteListFromDetail } from 'shared/utils/order-status-helper';
import { OrderNotFound } from './order-not-found';

const OrderDetailsModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const params = useParams<{ orderId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { order, isLoading } = useOrder(params.orderId);
  const closeHandler = () => {
    setIsOpen(false);
    navigate(getRouteListFromDetail(location));
    setIsOpen(true);
  };
  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      {isLoading ? <Loader /> : order != null ? <OrderSummary order={order} /> : <OrderNotFound />}
    </Modal>
  );
};

export { OrderDetailsModal };
