import { CancelTokenSource } from 'axios';
import { useState, useEffect, useCallback, useRef } from 'react';
import { IHttpFactory, IHttpFactoryType, renewCancelToken } from '.';
import { useInjection } from '../ioc';

interface GenericOrdersPage<T> {
  orders: T[];
  page: number;
  pageSize: number;
  totalPages: number;
}
interface GenericListResult<T> {
  items: T[];
  totalPages: number;
  isLoading: boolean;
  reload: () => Promise<void>;
}

const useGenericListLoader = <T>(
  api: string,
  page: number,
  pageSize: number = 24,
  filter: string = '',
): GenericListResult<T> => {
  const [result, setResult] = useState<T[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const cancelTokenRef = useRef<CancelTokenSource>(null);

  const reload = useCallback(async () => {
    const http = httpFactory.build(renewCancelToken(cancelTokenRef));
    try {
      const response = await http.get<GenericOrdersPage<T>>(
        `${api}?page=${page - 1}&pageSize=${pageSize}&filter=${encodeURI(filter)}`,
      );
      setResult(response.orders);
      setTotalPages(response.totalPages);
    } catch {
      setResult([]);
      setTotalPages(0);
      setIsLoading(false);
    }
  }, [api, filter, httpFactory, page, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    reload().then(() => setIsLoading(false));
  }, [reload]);

  return { items: result, isLoading, reload, totalPages };
};

export { useGenericListLoader };
