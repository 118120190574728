import React from 'react';
import { OrderAddress } from 'models';
import './order-summary-address.scss';
import { getCountryFromCode } from 'shared/utils/country-helper';

interface OrderSummaryAddressProps {
  address: OrderAddress;
}

const OrderSummaryAddress = (props: OrderSummaryAddressProps) => {
  const { address } = props;
  return (
    <>
      <div className="ordersummaryaddress">
        <div className="ordersummaryaddress__line ordersummaryaddress__company">{address.companyName}</div>
        <div className="ordersummaryaddress__line ordersummaryaddress__firstline">{address.recipientName}</div>
        <div className="ordersummaryaddress__line ordersummaryaddress__secondline">{address.street}</div>
        <div className="ordersummaryaddress__line ordersummaryaddress__location">{address.location}</div>
        <div className="ordersummaryaddress__line ordersummaryaddress__postalcode">
          {address.postalCode}&nbsp;
          {getCountryFromCode(address.countryCode)}
        </div>
      </div>
    </>
  );
};

export { OrderSummaryAddress };
