import { useEffect, useState } from 'react';
import { container } from '../../container';
import { IAuthenticationService, IAuthenticationServiceType } from '../../core/authentication';

export function useLicense(): boolean {
  const [licenseState, setlicenseState] = useState<boolean | null>(null);
  const authenticationService = container.get<IAuthenticationService>(IAuthenticationServiceType);

  useEffect(() => {
    async function hasLicense() {
      const hasLicense = await authenticationService.userHasLicence();
      setlicenseState(hasLicense);
    }

    hasLicense();

  }, [authenticationService]);

  return licenseState;
}