import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module';

class GoogleTagManagerService {
  private tracking = false;
  private tagManagerArgs: TagManagerArgs = null;

  public async init(googleTagManagerTrackingId: string) {
    if (googleTagManagerTrackingId) {
      this.tagManagerArgs = { gtmId: googleTagManagerTrackingId };
      TagManager.initialize(this.tagManagerArgs);
      this.tracking = true;
    }
  }

  public async pageView(pagePath: string, pageTitle: string) {
    if (this.tracking) {
      const data: DataLayerArgs = {
        dataLayer:
        {
          event: 'Pageview',
          pagePath: pagePath,
          pageTitle: pageTitle
        }
      };
      TagManager.dataLayer(data);
    }
  }
}

export const googleTagManagerService = new GoogleTagManagerService();
