import { injectable, inject } from 'inversify';
import type { IConfigurationService } from '../configuration';
import { IConfigurationServiceType } from '../configuration';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IInsightsService } from './iinsights-service';

@injectable()
export class AppInsightsService implements IInsightsService {
  @inject(IConfigurationServiceType)
  private _configuration: IConfigurationService;
  private _appInsights: ApplicationInsights;

  load(): void {
    const { applicationInsightsKey } = this._configuration.get();
    if (applicationInsightsKey) {
      this._appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: applicationInsightsKey,
          isCookieUseDisabled: true,
        },
      });
      this._appInsights.loadAppInsights();
      this.trackPageView();
    }
  }

  trackPageView(): void {
    if (this._appInsights) {
      this._appInsights.trackPageView({
        uri: `${window.location.origin}/${window.location.pathname}`,
      });
    }
  }
}
