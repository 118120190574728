import { OrderAddresses } from './Address';

export interface OrderTotals {
  netTotal: number;
  taxTotal: number;
  grossTotal: number;
  currency: string;
  totalPositions: number;
  totalProducts: number;
  totalItems: number;
}

export interface OrderItem {
  id: string;
  orderId: string;
  isRootItem: boolean;
  rootId: string;
  parentId: string;
  orderPosition: number;
  basketPosition: number;
  name: string;
  category: string;
  unitGrossPrice: number;
  totalGrossPrice: number;
  subtotalGrossPrice: number;
  unitNetPrice: number;
  totalNetPrice: number;
  subtotalNetPrice: number;
  subtotalProducts: number;
  subtotalItems: number;
  tax: number;
  currency: string;
  orderAmount: number;
  image: string;
  subItems: OrderItem[] | null;
}

export interface Order {
  id: string;
  organizationId: string;
  customerNumber: string;
  createdByUserId: string;
  orderNumber: string;
  swingOrderNumber: string;
  invoiceNumber: string;
  hasInvoicePdf: boolean;
  addresses: OrderAddresses;
  currency: string;
  totals: OrderTotals;
  status: number;
  createdOn: string;
  orderDate: string;
  deliveryDate: string;
  requestorName: string;
  requestorEmail: string;
  items: OrderItem[] | null;
  versionTag: string;
}

export enum OrderStatus {
  New = 0,
  Cancelled = 1,
  SentForProcessing = 2,
  LinkedToSwing = 3,
  Acknowledged = 4,
  Invoiced = 5,
  InvoiceSent = 6,
}

export enum OrderStatusName {
  'common.orders.status.new' = 0,
  'common.orders.status.cancelled' = 1,
  'common.orders.status.sentforprocessing' = 2,
  'common.orders.status.linkedtoswing' = 3,
  'common.orders.status.acknowledged' = 4,
  'common.orders.status.invoiced' = 5,
  'common.orders.status.invoicesent' = 6
}
