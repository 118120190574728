import { useEffect, useState } from 'react';
import { IAuthenticationServiceType, IAuthenticationService } from 'core/authentication';
import { User } from 'oidc-client';
import { container } from '../../container';
import { State } from 'core/types';

export function useUser(): State<User> {
  const [userState, setUserState] = useState<State<User>>({ loaded: false, data: null });
  const authenticationService = container.get<IAuthenticationService>(IAuthenticationServiceType);

  useEffect(() => {
    async function getUser() {
      const user = await authenticationService.getUser();
      setUserState({ loaded: true, data: user });
    }

    getUser();

  }, [setUserState, authenticationService]);

  return { loaded: userState.loaded, data: userState.data };
}