import React from 'react';
import { useTranslation } from 'react-i18next';

import e404 from './error_404.svg';

const OrderNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="hw-error-page__container hw-centered-content">
      <img alt="Haworth logo" className="hw-error-page__logo" src={e404} />
      <div className="hw-error-page__description">{t('common.errors.not_found.description')}</div>
    </div>
  );
};

export { OrderNotFound };
