import React from 'react';
import './invoice-links.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { loadInvoice } from './useSwingInvoice';
import { IHttpFactory, IHttpFactoryType } from 'core/http';
import { IConfigurationService, IConfigurationServiceType } from 'core/configuration';
import { useInjection } from 'core/ioc';

export const InvoiceLinks = ({ invoiceNumber }: { invoiceNumber: string }) => {
  const navigate = useNavigate();
  const match = useResolvedPath('');
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const downloadPDF = () => {
    loadInvoice(httpFactory, configurationService, invoiceNumber).then((pdf) => {
      const linkSource = `data:application/pdf;base64,${pdf.content}`;
      const downloadLink = document.createElement('a');
      const fileName = `Haworth_Invoice_${invoiceNumber}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const seeInvoice = () => {
    navigate(`${match.pathname}/invoice/${invoiceNumber}${window.location.search}`);
  };
  const { t } = useTranslation();
  return (
    <span className="invoice-links">
      <button
        title={t('orders_list.table.invoice_view_title')}
        type="button"
        className="btn btn-primary"
        onClick={seeInvoice}
      >
        <i className="bi bi-eye"></i>
      </button>
      <button
        title={t('orders_list.table.invoice_download_title')}
        type="button"
        className="btn btn-primary"
        onClick={downloadPDF}
      >
        <i className="bi bi-cloud-download"></i>
      </button>
    </span>
  );
};
