import React from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from './loader/loader';
import { useUser } from 'shared/hooks/use-user';
import { useLicense } from 'shared/hooks/use-licences';
import { IAuthenticationService, IAuthenticationServiceType } from 'core/authentication';
import { container } from 'container';

export function RequireAuth({ children }: React.PropsWithChildren): React.JSX.Element {
  const authenticationService = container.get<IAuthenticationService>(IAuthenticationServiceType);
  const { data: user, loaded } = useUser();
  const licenseState = useLicense();

  if (!loaded) {
    return <Loader />;
  }

  if (!user || user.expired) {
    authenticationService.login();
    return <Loader />;
  }

  if (licenseState == null) {
    return <Loader />;
  }

  if (!licenseState) {
    return <Navigate to="/forbidden" />;
  }

  return <>{children}</>;
}
