import React from 'react';
import './order-item-image.scss';

import noImage from './no-image.png';

interface ProductImageProps {
  image?: string;
}

const OrderItemImage = (props: ProductImageProps) => {
  const image =
    props.image === '' || props.image === null || props.image === undefined
      ? noImage
      : props.image;
  return (
    <div className="orderitemimage">
      <img alt="product" className="thumb" src={image} />
      <div className="orderitemimage__detail">
        <img alt="product big" src={image} />
      </div>
    </div>
  );
};

export { OrderItemImage };
