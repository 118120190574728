import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SwingOrder } from '../../models/SwingOrder';
import { moneyFormatter } from '../../shared/utils/money-formatter';
import { InvoiceLinks } from '../invoice-viewer';
import { swingOrderStatusStyle, dateFormat } from '../../shared/utils/order-status-helper';

const SwingOrderRow = ({ order }: { order: SwingOrder }): React.JSX.Element => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const openOrderDetail = useCallback(
    (orderId: string) => {
      navigate(`details/${orderId}${window.location.search}`);
    },
    [navigate],
  );

  const severity = swingOrderStatusStyle[order.overallStatus];
  return (
    <>
      <tr>
        <td className={`table-row--${severity}`}>{order.orderNumber}</td>
        <td>
          <button className="btn btn-primary" onClick={() => openOrderDetail(order.id)}>
            <i title={t('orders_list.table.order_details_title')} className="bi bi-card-list"></i>
          </button>
        </td>
        <td>{order.customerOrderNumber}</td>
        <td className={`text-${severity}`}>{t(`common.orders.status.${order.overallStatus}`)}</td>
        <td>{`${moment(order.orderDate).format(dateFormat)}`}</td>
        <td>{`${
          order.estimatedDeliveryDate == null ? '*' : moment(order.estimatedDeliveryDate).format(dateFormat)
        }`}</td>
        <td>
          {moneyFormatter({
            amount: order.itemsNetTotal,
            currency: order.currency,
            language: i18n.language,
          })}
        </td>
        <td>{`${order.customerName} (${order.customerNumber})`}</td>
        <td>{order.hasInvoicePdf && <InvoiceLinks invoiceNumber={order.invoiceNumber} />}</td>
      </tr>
      <tr className="spacer"></tr>
      <tr className="spacer"></tr>
    </>
  );
};

export { SwingOrderRow };
