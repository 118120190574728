import './styles/main.scss';
import './polyfills';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configureI18N } from './i18n';
import { container } from './container';
import { IoCProvider } from './core/ioc';
import { IConfigurationService, IConfigurationServiceType } from './core/configuration';
import { App } from './app';
import './register-web-components';
import { IInsightsServiceType, IInsightsService } from './core/insights';
import { analyticsService } from './shared/analytics/analytics.service';
import { Loader } from './shared/loader/loader';

const configurationService = container.get<IConfigurationService>(IConfigurationServiceType);
const insightsService = container.get<IInsightsService>(IInsightsServiceType);

async function bootstrap(): Promise<void> {
  await configurationService.load();
  const configuration = configurationService.get();
  await configureI18N(configuration.suiteOrigin);
  insightsService.load();

  const rootElement = createRoot(document.getElementById('root'));

  analyticsService.init(configuration);
  rootElement.render(
    <IoCProvider container={container}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </IoCProvider>,
  );
}

bootstrap();
