import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SwingOrderSummaryAddress } from './swing-order-summary-address';
import { SwingOrder } from '../../../models/SwingOrder';

interface SwingOrderSummaryHeaderProps {
  order: SwingOrder;
}

const SwingOrderSummaryHeader = (props: SwingOrderSummaryHeaderProps) => {
  const {
    orderNumber,
    orderDate,
    customerNumber,
    customerOrderNumber,
    deliveryDate,
    invoicingAddress,
    deliveryAddress,
  } = props.order;
  const dateFormat = 'DD-MM-YYYY';
  const { t } = useTranslation();
  return (
    <table className="swingordersummarysection swingordersummarysection__header">
      <tbody>
        <tr>
          <td style={{ width: '20%' }}>
            <span className="swingordersummarysection__label">{t('order_details.labels.orderNumber')}</span>{' '}
            {orderNumber}
          </td>
          <td style={{ width: '15%' }}>
            <span className="swingordersummarysection__label">{t('order_details.labels.customerOrderNumber')}</span>{' '}
            {customerOrderNumber}
          </td>
          <td style={{ width: '15%' }}>
            <span className="swingordersummarysection__label">{t('order_details.labels.customerNumber')}</span>{' '}
            {customerNumber}
          </td>
          <td style={{ width: '25%' }}>
            <span className="swingordersummarysection__label">{t('order_details.labels.orderDate')}:</span>{' '}
            {moment(orderDate).format(dateFormat)}
          </td>
          <td style={{ width: '25%' }}>
            <span className="swingordersummarysection__label">{t('order_details.labels.deliveryDate')}:</span>{' '}
            {moment(deliveryDate).format(dateFormat)}
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{ verticalAlign: 'top' }}>
            <div className="swingordersummarysection__label">{t('order_details.labels.billingAddress')}</div>
            <SwingOrderSummaryAddress address={invoicingAddress} />
          </td>
          <td colSpan={2} style={{ verticalAlign: 'top' }}>
            <div className="swingordersummarysection__label">{t('order_details.labels.shippingAddress')}</div>
            <SwingOrderSummaryAddress address={deliveryAddress} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { SwingOrderSummaryHeader };
