import { ContainerModule, interfaces } from 'inversify';
import { IAuthenticationServiceType, IAuthenticationService, AuthenticationService } from './authentication';
import { ConfigurationService, IConfigurationService, IConfigurationServiceType } from './configuration';
import { IHttpFactoryType, HttpFactory, IHttpFactory } from './http';
import {
  IInsightsService,
  IInsightsServiceType,
  AppInsightsService
} from './insights';

export const coreContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAuthenticationService>(IAuthenticationServiceType)
    .to(AuthenticationService)
    .inSingletonScope();

  bind<IConfigurationService>(IConfigurationServiceType)
    .to(ConfigurationService)
    .inSingletonScope();

  bind<IHttpFactory>(IHttpFactoryType)
    .to(HttpFactory)
    .inSingletonScope();

  bind<IInsightsService>(IInsightsServiceType)
    .to(AppInsightsService)
    .inSingletonScope();
});