import React, { useEffect } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Header } from 'shared/layout/header';
import { Orders } from './orders/Orders';
import { Forbidden, NotFound } from 'shared/error-pages';
import { RequireAuth } from 'shared/private-route';
import { useSigninRedirect } from 'shared/hooks';
import { ServiceBar } from 'shared/layout/service-bar/service-bar';
import { Loader } from 'shared/loader/loader';
import { analyticsService } from './shared/analytics/analytics.service';
import { ServiceFooter } from 'shared/layout/service-bar/service-footer';
import 'react-toastify/dist/ReactToastify.css';

export function App(): React.JSX.Element {
  const isSignInRedirect = useSigninRedirect();
  let location = useLocation();
  useEffect(() => {
    analyticsService.pageView(location.pathname, location.pathname);
  }, [location]);

  return (
    <div>
      <ServiceBar />
      {isSignInRedirect ? (
        <div className="hw-loader-page">
          <Loader />
        </div>
      ) : (
        <>
          <Header />
          <main className="hw-page-content">
            <Routes>
              <Route
                path="/orders/*"
                element={
                  <RequireAuth>
                    <Orders />
                  </RequireAuth>
                }
              />
              <Route
                path="/not-found"
                element={
                  <RequireAuth>
                    <NotFound />
                  </RequireAuth>
                }
              />
              <Route path="/forbidden" element={<Forbidden />} />
              <Route
                path="*"
                element={
                  <RequireAuth>
                    <Navigate to="/orders" />
                  </RequireAuth>
                }
              />
            </Routes>
          </main>
          <ServiceFooter />
        </>
      )}
      <ToastContainer />
    </div>
  );
}
